import { useRouteStore } from '~/store/routesStore.ts'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) return
  const routeStore = useRouteStore()

  routeStore.setCurrentRouteByPath(to.path)
  if (routeStore.currentRoute) return

  const { data: shopRoutes, error } = await useFetch(`/api/shop/item-routes?friendlyUrl=${to.path}`)

  if (error.value) {
    console.error('Failed to fetch user shopRoutes:', error.value)
    return
  }

  routeStore.addShopRoutes(shopRoutes.value)
  routeStore.setCurrentRouteByPath(to.path)
})
